// @ts-nocheck
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { useRecoilState, useSetRecoilState } from "recoil";
import { toggleToasterState, userLoginSitesInfo } from "../../atom/generic";
import { Spinner } from "react-bootstrap";
import { AlterError } from "../shared/alter-error";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getUserDetails } from "../../utility/jwt";

interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
  siteId: { value: string; label: string }[];
  verificationStatus: string;
}
export const UserUpdateForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [totalSites, setTotalSitesByUser] = useRecoilState(userLoginSitesInfo);
  const [isSubmittedSuccessful, setIsSubmittedSuccessful] = useState(false);
  const animatedComponents = makeAnimated();

  const [options, setOptions] = useState<string[]>([]);
  /* const options = totalSites.map((site: any) => ({
    value: site.id,
    label: site.siteName,
  })); */

  console.log("AdminSites", totalSites);

  const { user } = getUserDetails();

  useEffect(() => {
    const getAdminUser = async () => {
      await Http.request({
        url: `${process.env.REACT_APP_API_URL}users/${user.userId}`,
        method: "get",
        headers: apiHeaders(),
      })
        .then((result) => {
          const user = result.data.user;
          setTotalSitesByUser(user.sites);
          const sites = user.sites.map((site: any) => ({
            value: site.id,
            label: site.siteName,
          }));
          if (sites.length) {
            setOptions(sites);
          }
          console.log(user.sites);
        })
        .catch((error) => {
          if (error.response.status) {
            console.error("Error getting the session user info." + error);
          }
        });
    };
    getAdminUser();
  }, []);

  useEffect(() => {
    getOneUser();
  }, [id]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Firstname is required."),
    lastName: Yup.string().required("Lastname is required."),
    email: Yup.string().email().required("Email is required."),
    role: Yup.string().required("Role is required."),
    siteId: Yup.array()
      .min(1, "Please select at least one site.")
      .when("role", {
        is: "gate_keeper",
        then: (schema) =>
          schema.max(1, "You can only select one site for gate keeper role."),
        otherwise: (schema) => schema,
      })
      .required(),
    phoneNumber: Yup.string()
      .required("Phone no. is required.")
      .max(10, "Phone no. must be of 10 digits.")
      .matches(/^(0|91)?[6-9][0-9]{9}$/, "Phone no. is invalid."),
    verificationStatus: Yup.string()
      .notOneOf([""], "You must select the status.")
      .required("You must select the status."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IUserForm>({
    resolver: yupResolver(validationSchema),
  });

  const getOneUser = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${id}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        const user = result.data.user;
        console.log(user);
        setValue("firstName", user.firstName);
        setValue("lastName", user.lastName);
        setValue("email", user.email);
        setValue("role", user.role);
        setValue("phoneNumber", user.phoneNumber);
        setValue("verificationStatus", user.verificationStatus);
        const sites = JSON.parse(user.siteId);
        setValue("siteId", sites);
        setSelectedValues(sites);
      })
      .catch((error) => {
        if (error.response.status) {
          setErrorMessage(error.response.data.message);
        }
      });
  };

  const onSubmitHandler = async (data: IUserForm) => {
    setIsLoading(true);
    setIsSubmittedSuccessful(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${id}`,
      method: "put",
      data: data,
      headers: apiHeaders(),
    })
      .then((result) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "User",
            message: result.message,
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSubmittedSuccessful(false);
        console.log(error);
        // if (error.response.status >= 400 || error.response.status <= 500) {
        //   setErrorMessage(error.response.data.message);
        // }
        if (error.response?.status) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            "Network error. Please check your internet connection."
          );
        }
      });
  };

  return (
    <>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {errorMessage && <AlterError message={errorMessage} />}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Edit Contractor</h5>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      First Name :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.firstName ? "is-invalid" : ""
                        }`}
                        id="firstName"
                        {...register("firstName")}
                      />
                      <div className="invalid-feedback">
                        {errors.firstName?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.lastName ? "is-invalid" : ""
                        }`}
                        id="lastName"
                        {...register("lastName")}
                      />
                      <div className="invalid-feedback">
                        {errors.lastName?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Email Address :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        {...register("email")}
                        readOnly
                        disabled
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Role :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.role ? "is-invalid" : ""
                        }`}
                        id="email"
                        {...register("role")}
                        readOnly
                        disabled
                      />
                      <div className="invalid-feedback">
                        {errors.role?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Sites :
                    </label>
                    <div className="col-sm-10">
                      <Select
                        className={`basic-multi-select ${
                          errors.siteId ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        isMulti
                        isClearable
                        components={animatedComponents}
                        options={options}
                        value={options?.filter((option) =>
                          selectedValues?.includes(option.value)
                        )}
                        onChange={(sites: any) => {
                          setSelectedValues(
                            sites.map((option: any) => option.value)
                          );
                          setValue(
                            "siteId",
                            sites.map((option: any) => option.value)
                          );
                        }}
                      />
                      <div className="invalid-feedback">
                        {errors.siteId?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPassword3"
                      className="col-sm-2 col-form-label"
                    >
                      Phone No :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.phoneNumber ? "is-invalid" : ""
                        }`}
                        id="phone"
                        maxLength={10}
                        {...register("phoneNumber")}
                      />
                      <div className="invalid-feedback">
                        {errors.phoneNumber?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Status :
                    </label>
                    <div className="col-sm-10">
                      <select
                        {...register("verificationStatus")}
                        className={`form-select ${
                          errors.verificationStatus ? "is-invalid" : ""
                        }`}
                        id="role"
                      >
                        <option value="pending">Pending</option>
                        <option value="hold">Hold</option>
                        <option value="approved">Approved</option>
                        <option value="reject">Rejected</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.verificationStatus?.message}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-2"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                    {!isSubmittedSuccessful && (
                      <button
                        type="reset"
                        className="btn btn-secondary"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
