import { Route, Routes } from "react-router-dom";
import Protected from "../protected";
import { User } from "../pages/user/user-list";
import { UpdateUser } from "../pages/user/update-user";
import { AddUser } from "../pages/user/add-user";
import { NotFound } from "../pages/not-found";

export const UsersRoutes = () => {
  return (
    /*  <Protected> */
    <Routes>
      <Route>
        <Route index element={<User />} />
        <Route path="edit/:id" element={<UpdateUser />} />
        <Route path="add" element={<AddUser />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
    /*  </Protected> */
  );
};
