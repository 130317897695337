import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  toggleUserDeleteModalState,
  totalUsersCountState,
  userIdState,
  usersListState,
} from "../../atom/users";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { DeleteUserModal } from "./delete-user-modal";
import { Skelton } from "../shared/skelton";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { userLoginSitesInfo } from "../../atom/generic";
import { DUMMY_USER } from "../../shared/user";

type UserStatusProps = {
  status: string;
};

export const UsersTable = () => {
  const [userList, setUsersList] = useRecoilState(usersListState);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useRecoilState(totalUsersCountState);
  const setToggleUserDeleteModal = useSetRecoilState(
    toggleUserDeleteModalState
  );
  const setUserId = useSetRecoilState(userIdState);
  const startIndex = (page - 1) * limit + 1;
  const animatedComp = makeAnimated();
  const totalSites = useRecoilValue(userLoginSitesInfo);

  const options = totalSites.map((site: any) => ({
    value: site.id,
    label: site.siteName,
  }));

  useEffect(() => {
    getUsers();
  }, [page, limit, searchTerm]);

  const getUsers = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
    })
      .then((response) => {
        setUsersList(response.data.user);
        setTotalUsers(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setUsersList([]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };

  const handleDeleteSuccess = () => {
    if (page !== 1 && page * limit >= totalUsers && userList.length === 1) {
      setPage(page - 1);
    }
    getUsers();
  };

  const searchUserByName = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const UserStatusBagdeClass = ({ status }: UserStatusProps) => {
    let badgeClass: string = "";
    switch (status) {
      case "Active":
        badgeClass = "bg-success";
        break;

      case "Revoked":
        badgeClass = "bg-secondary";
        break;

      case "Rejected":
        badgeClass = "bg-danger";
        break;

      case "Pending":
        badgeClass = "bg-warning";
        break;

      default:
        badgeClass = "";
        break;
    }
    return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
  };

  const _handleScroll = () => {
    console.log("scrolling");
  };

  useEffect(() => {
    window.addEventListener("scroll", _handleScroll);

    return () => {
      window.removeEventListener("scroll", _handleScroll);
    };
  }, []);

  const transformRole = (role: any) => {
    const roleTransformations: any = {
      gate_keeper: "Gate Keeper",
      admin: "Admin",
      super_admin: "Super Admin",
      business_user: "Business User",
      adid_ldap: "Adid Ldap",
    };

    return roleTransformations[role] || role;
  };
  const transformStatus = (status: any) => {
    const statusTransformations: any = {
      pending: "Pending",
      hold: "Revoked",
      approved: "Active",
      reject: "Rejected",
    };
    return statusTransformations[status] || status;
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Contractor list</h5>
          <div className="datatable-wrapper datatable-loading card-footer sortable searchable fixed-columns">
            <div className="datatable-top">
              <div className="datatable-dropdown"></div>
              {/* <div className="datatable-dropdown">
                <div className="row" style={{ width: "35rem" }}>
                  <Select
                    className="datatable-selector row-gap-1"
                    isMulti
                    isClearable
                    isSearchable
                    placeholder="Search by site..."
                    components={animatedComp}
                    options={options}
                  />
                </div>
              </div> */}
              <div className="datatable-search">
                <Link to="/users/add">
                  <button className="btn btn-primary mx-2">
                    <i className="bi bi-plus-lg"></i> Add Contractor
                  </button>
                </Link>
                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={searchUserByName}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col">S.No.</th>
                    <th scope="col">Action</th>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Mobile No.</th> */}
                    <th scope="col">Email Id</th>
                    {/* <th scope="col">Site</th>
                    <th scope="col">Role</th>*/}
                    <th scope="col">Status</th> 
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton totalCol={7}></Skelton>}
                  {!loading && DUMMY_USER.length === 0 && (
                    <tr>
                      <td colSpan={6}>Contractor not found.</td>
                    </tr>
                  )}
                  {!loading &&
                    DUMMY_USER.length > 0 &&
                    DUMMY_USER.map((user: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{startIndex + index}</td>
                          <td>
                            <Link to={`/users/edit/${user.id}`}>
                              <button className="btn btn-primary me-1">
                                <i className="bi bi-pencil-square"></i>
                                {/* <i className="bi bi-three-dots-vertical"></i> */}
                              </button>
                            </Link>
                            <Link to={``}>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  setUserId(user.id);
                                  setToggleUserDeleteModal(true);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </Link>
                          </td>
                          <td>{user.name}</td>
                          {/* <td>{user.phoneNumber}</td> */}
                          <td>{user.email}</td>
                          <td className="text-capitalize">
                            <UserStatusBagdeClass
                              status={transformStatus(user.verificationStatus)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {DUMMY_USER.length !== 0 && (
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-3 pagination-select-box">
                    <span className="pagination-span">Rows per page:</span>
                    <select
                      className="pagination-select"
                      onChange={(e) =>
                        handleLimitChange(Number(e.target.value))
                      }
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                  <div className="col-lg-3 pagination-box">
                    <div className="pagination-num">
                      {(page - 1) * limit + 1} -{" "}
                      {Math.min(page * limit, totalUsers)} of {totalUsers}
                    </div>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`btn pagination-btn`}
                      onClick={() => handlePageChange(page + 1)}
                      disabled={page * limit >= totalUsers}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteUserModal onDeleteSuccess={handleDeleteSuccess} />
    </>
  );
};
