import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { apiHeaders } from "../../utility/generic";
import { Http } from "../../utility/http";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { toggleToasterState, userLoginSitesInfo } from "../../atom/generic";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AlterError } from "../shared/alter-error";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getUserDetails } from "../../utility/jwt";

interface IUserForm {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  phoneNumber: string;
  siteId: { value: string; label: string }[];
  password: string;
  confirmPassword: string;
}
export const AddUserForm = () => {
  const navigate = useNavigate();
  const setToggleToaster = useSetRecoilState(toggleToasterState);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = getUserDetails();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [totalSites, setTotalSitesByUser] = useState([{ id: "", siteName: "" }]);
  const [isSubmittedSuccessful , setIsSubmittedSuccessful] = useState(false);
  const animatedComponents = makeAnimated();

  const getAdminUser = async () => {
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users/${user.userId}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((result) => {
        const user = result.data.user;
        setTotalSitesByUser(user.sites);
        // console.log(result);
        // console.log(user.sites);
      })
      .catch((error) => {
        if (error.response.status) {
          console.error("Error getting the session user info." + error);
        }
      });
  };
  useEffect(() => {
    getAdminUser();
  }, []);

  const options = totalSites.map((site: any) => ({
    value: site.id,
    label: site.siteName,
  }));

  console.log("totalSites", totalSites);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required."),
    lastName: Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required.")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/, "Email is invalid."),
    role: Yup.string()
      .notOneOf([""], "You must select the role.")
      .required("You must select the role."),
    siteId: Yup.array().min(1, "Please select at least one site.")
      .when('role',{
        is: 'gate_keeper',
        then: (schema) => schema.max(1, 'You can only select one site for gate keeper role.'),
        otherwise: (schema) => schema
      })
      .required(),
    phoneNumber: Yup.string()
      .required("Phone no. is required.")
      .max(10, "Phone no. must be of 10 digits.")
      .matches(/^(0|91)?[6-9][0-9]{9}$/, "Phone no. is invalid."),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must be at least 8 characters.")
      .max(20, "Password must not exceed 20 characters.")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Password should contain atleast one special character, one uppercase letter, one lowercase letter, and one number."
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required.")
      .oneOf([Yup.ref("password")], "Password do not match."),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors},
  } = useForm<IUserForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      role: "",
    },
  });

  const handleNumericInput = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
  };

  const onSubmitHandler = async (data: IUserForm) => {
    console.log(data);
    setIsLoading(true);
    setIsSubmittedSuccessful(true)
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}users`,
      method: "post",
      data: data,
      headers: apiHeaders(),
    })
      .then((response) => {
        setToggleToaster({
          isShow: true,
          data: {
            title: "User",
            message: response.message,
          },
        });
        setTimeout(() => {
          navigate(-1);
          setIsLoading(false);
        }, 1500);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsSubmittedSuccessful(false)
        // if (error.response.status >= 400 || error.response.status <= 500) {
        //   setErrorMessage(error.response.data.message);
        // }
        if (error.response?.status) {
          setErrorMessage(error.response.data.message);
        }
        else{
          setErrorMessage("Network error. Please check your internet connection.");
        }
      });
  };

  return (
    <>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {errorMessage && <AlterError message={"*" + errorMessage} />}
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Add Contractor</h5>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputFirstName3"
                      className="col-sm-2 col-form-label"
                    >
                      First Name :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.firstName ? "is-invalid" : ""
                        }`}
                        {...register("firstName")}
                        placeholder="Enter first name"
                      />
                      <div className="invalid-feedback">
                        {errors.firstName?.message}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="inputLastName3"
                      className="col-sm-2 col-form-label"
                    >
                      Last Name :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.lastName ? "is-invalid" : ""
                        }`}
                        {...register("lastName")}
                        placeholder="Enter last name"
                      />
                      <div className="invalid-feedback">
                        {errors.lastName?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Email Address :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="inputEmail"
                        {...register("email")}
                        placeholder="Enter email address"
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Role :
                    </label>
                    <div className="col-sm-10">
                      <select
                        {...register("role")}
                        className={`form-select ${
                          errors.role ? "is-invalid" : ""
                        }`}
                        id="role"
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        <option value="admin">Admin</option>
                        <option value="business_user">Business User</option>
                        <option value="gate_keeper">Gate Keeper</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.role?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Sites :
                    </label>
                    <div className="col-sm-10">
                      <Select
                        className={`basic-multi-select ${
                          errors.siteId ? "is-invalid" : ""
                        }`}
                        classNamePrefix="select"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isMulti
                        {...register("siteId")}
                        isClearable
                        placeholder="Select a site"
                        options={options}
                        onChange={(sites: any) => {
                          setSelectedValues(
                            sites.map((option: any) => option.value)
                          );
                          setValue(
                            "siteId",
                            sites.map((option: any) => option.value)
                          );
                        }}
                        value={options?.filter((option) =>
                          selectedValues.includes(option.value)
                        )}
                      />

                      <div className="invalid-feedback">
                        {errors.siteId?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputphone3"
                      className="col-sm-2 col-form-label"
                    >
                      Phone :
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        {...register("phoneNumber")}
                        maxLength={10}
                        placeholder="Enter phone no"
                        className={`form-control ${
                          errors.phoneNumber ? "is-invalid" : ""
                        }`}
                        id="Phone"
                        onInput={handleNumericInput}
                      />
                      <div className="invalid-feedback">
                        {errors.phoneNumber?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="inputPassword3"
                      className="col-sm-2 col-form-label"
                    >
                      Password :
                    </label>
                    <div className="col input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        maxLength={20}
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        id="inputPassword"
                        {...register("password")}
                        placeholder="Enter password"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span
                            className={`${
                              showPassword
                                ? "bi bi-eye-slash-fill"
                                : "bi bi-eye-fill"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setShowPassword((prevState) => !prevState)
                            }
                          ></span>
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errors.password?.message}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="confirmPassword"
                      className="col-sm-2 col-form-label"
                    >
                      Confirm Password :
                    </label>
                    <div className="col input-group">
                      <input
                        {...register("confirmPassword")}
                        maxLength={20}
                        type={showConfirmPassword ? "text" : "password"}
                        className={`form-control ${
                          errors.confirmPassword ? "is-invalid" : ""
                        }`}
                        id="confirmPassword"
                        placeholder="Confirm password"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span
                            className={`${
                              showConfirmPassword
                                ? "bi bi-eye-slash-fill"
                                : "bi bi-eye-fill"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setConfirmShowPassword((prevState) => !prevState)
                            }
                          ></span>
                        </div>
                      </div>
                      <div className="invalid-feedback">
                        {errors.confirmPassword?.message}
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-2"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          className="me-1"
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    {
                      !isSubmittedSuccessful && <button
                          type="reset"
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Cancel
                        </button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
