import { ReactNode } from "react";
import { Header } from "../components/heder";
import { SiderBar } from "../components/sider-bar";
import { Footer } from "../components/footer";

interface Props {
  children?: ReactNode;
}
export const MainLayout = ({ children, ...props }: Props) => {
  return (
    <>
    <div className="layout">
      <Header></Header>
     {/*  <SiderBar></SiderBar> */}
      <main id="main" className="main">
        {children}
      </main>
      <Footer></Footer>
      </div>
    </>
  );
};
