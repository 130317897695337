// @ts-nocheck
import { MainLayout } from "../layout/main-layout";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { loadModules } from "esri-loader";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import * as Yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DUMMY_USER, ENGINNER_USER } from "../shared/user";
import { Http } from "../utility/http";
import { LayerList } from "../components/dashboard/layer-list";
import p1 from "../assets/images/excavator.png";
import p2 from "../assets/images/concreting.png";
import p3 from "../assets/images/mounting.png";
import p4 from "../assets/images/solar.png";
import MapView from "@arcgis/core/views/MapView.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Map from "@arcgis/core/Map.js";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import CSVLayer from "@arcgis/core/layers/CSVLayer.js";
import Basemap from "@arcgis/core/Basemap.js";
import FeatureTable from "@arcgis/core/widgets/FeatureTable.js";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";

export const Temp = () => {
  const mapDivRef = useRef(null);
  const [selectedLayer, setSelectedLayer] = useState("excavated");
  const [status, setStatus] = useState("");

  const handleClose = () => {
    setShow(false);
    reset();
    setRejectedEntries([]);
  };

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("Contractor is required."),
    /* range: Yup.array().of(
      Yup.object().shape({
        from1: Yup.string().required("From1 is required"),
        to1: Yup.string().required("To1 is required"),
        from2: Yup.string().required("From2 is required"),
        to2: Yup.string().required("To2 is required"),
      })
    ), */
  });

  const [mapView, setMapView] = useState(null);
  const [layers, setLayers] = useState({});
  const [selectedGeometry, setSelectedGeometry] = useState([]);

  useEffect(() => {
    if (mapView) {
      console.log("I am in ");
      return;
    }

    const map = new Map({
      basemap: "streets-vector",
    });

    const view = new MapView({
      container: mapDivRef.current,
      //container: "viewDiv",
      map: map,
      zoom: 13,
      center: [-122.43, 37.78], // Your center coordinates
    });
  }, []);

  return (
    <div
      ref={mapDivRef}
      style={{ height: "80vh", width: "100%" }}
    />
  );
};
