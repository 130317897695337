import Cookies from "js-cookie";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { activeMenuState } from "../atom/generic";
import { getUserDetails } from "../utility/jwt";
import profile3 from "../assets/images/Profile-512.webp";
import logo from "../assets/images/Adani_2012_logo.png.webp";
// import profile3 from '../assets/images/computer-icons-system-administrator-user-user-avatar.jpg';
// import profile3 from '../assets/images/363640-200.png';

export const Header = () => {
  const userInfo = getUserDetails();
  const user = userInfo.user;

  const activeMenu = useRecoilValue(activeMenuState);

  const toggleSidebar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };
  const navigate = useNavigate();
  const logout = () => {
    Cookies.remove("token");
    window.location.href = "";
  };

  const transformRole = (role: any) => {
    const roleTransformations: any = {
      super_admin: "Super Admin",
      admin: "Admin",
      gate_keeper: "Gate Keeper",
      adid_ldap: "ADID LDAP",
      business_user: "Business User",
    };
    return roleTransformations[role] || role;
  };

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a href="/dashboard" className="logo d-flex align-items-center">
            <img src={logo} style={{ maxHeight: "70px" }} alt="" />
          </a>
          {/* <i
            className="bi bi-list toggle-sidebar-btn"
            onClick={toggleSidebar}
          ></i> */}
          <Link className="btn btn-primary" to="/" style={{ color: "#fff" }}>
            Home
          </Link>
          &nbsp;&nbsp;
          <Link
            className="btn btn-primary"
            to="/dashboard"
            style={{ color: "#fff" }}
          >
            Dashboard
          </Link>
          &nbsp;&nbsp;
          <Link
            className="btn btn-primary"
            to="https://experience.arcgis.com/experience/12b67dce899d409d9cca2423183d2fca"
            target="_blank"
            style={{ color: "#fff" }}
          >
            View on map
          </Link>
          &nbsp;&nbsp;
          <Link
            className="btn btn-primary"
            to="/users"
            style={{ color: "#fff" }}
          >
            Contractor
          </Link>
        </div>

        {/* <div className="search-bar">
          <form
            className="search-form d-flex align-items-center"
            method="POST"
            action="#"
          >
            <input
              type="text"
              name="query"
              placeholder="Search"
              title="Enter search keyword"
            />
            <button type="submit" title="Search">
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search"></i>
              </a>
            </li>

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have 4 new notifications
                  <a href="#">
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer">
                  <a href="#">Show all notifications</a>
                </li>
              </ul>
            </li> */}
            {/* 
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-icon"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-chat-left-text"></i>
                <span className="badge bg-success badge-number">3</span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li className="dropdown-header">
                  You have 3 new messages
                  <a href="#">
                    <span className="badge rounded-pill bg-primary p-2 ms-2">
                      View all
                    </span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-1.jpg"
                      alt=""
                      className="rounded-circle"
                    />
                    <div>
                      <h4>Maria Hudson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>4 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-2.jpg"
                      alt=""
                      className="rounded-circle"
                    />
                    <div>
                      <h4>Anna Nelson</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>6 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="message-item">
                  <a href="#">
                    <img
                      src="assets/img/messages-3.jpg"
                      alt=""
                      className="rounded-circle"
                    />
                    <div>
                      <h4>David Muldon</h4>
                      <p>
                        Velit asperiores et ducimus soluta repudiandae labore
                        officia est ut...
                      </p>
                      <p>8 hrs. ago</p>
                    </div>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="dropdown-footer">
                  <a href="#">Show all messages</a>
                </li>
              </ul>
            </li> */}

            <li className="nav-item dropdown pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <img
                  src={profile3}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: "24%", height: "24%", objectFit: "contain" }}
                />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  {user.firstName} {user.lastName}
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>
                    {user.firstName} {user.lastName}
                  </h6>
                  <span>{transformRole(user.role)}</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink
                    className={
                      "dropdown-item d-flex align-items-center " +
                      (activeMenu !== "profile" ? "" : "active")
                    }
                    to={"/profile"}
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </NavLink>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className={
                      "dropdown-item d-flex align-items-center " +
                      (activeMenu !== "setting" ? "" : "active")
                    }
                    to={`/setting`}
                  >
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <Link
                    className="dropdown-item d-flex align-items-center"
                    to=""
                    onClick={logout}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
