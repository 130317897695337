import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { MainLayout } from "../../layout/main-layout";
import { useSetRecoilState } from "recoil";
import { activeMenuState } from "../../atom/generic";
import { UsersTable } from "../../components/user/user-table";
import { Toaster } from "../../components/shared/toaster";

export const User = () => {
  const setActiveMenu = useSetRecoilState(activeMenuState);
  setActiveMenu("user");
  return (
    <>
      <MainLayout>
        <div className="">
          <h2
            className="pagetitle"
            style={{
              color: "#012970",
              fontWeight: "600",
              marginBottom: 0,
              fontSize: "24px",
              fontFamily: "Nunito, sans-serif",
            }}
          >
            Contractor
          </h2>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Contractor</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <UsersTable />
            </div>
          </div>
        </section>
        <Toaster></Toaster>
      </MainLayout>
    </>
  );
};
