import ProgressBar from "react-bootstrap/ProgressBar";
import { MainLayout } from "../layout/main-layout";
import { useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { DUMMY_USER } from "../shared/user";

export const Dashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  );

  /* Progress Chart */
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
    },
  };
  const labels = ["2001", "2002", "2003", "2004", "2005", "2006", "2007"];
  const data = {
    labels,
    datasets: [
      {
        label: "Billed",
        data: [1, 2, 3, 4, 5, 6, 7],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Assigned",
        data: [11, 21, 31, 41, 15, 61, 71],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  /* Progress Chart */

  const pieData = {
    labels: ["Pending", "Completed", "Assigned"],
    datasets: [
      {
        label: "",
        data: [30, 40, 75],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    maintainAspectRatio: true,
    height: 400, // Set your desired height
    width: 400, // Set your desired width
  };

  return (
    <>
      <MainLayout>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-2">
              <div className="row">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Assigned</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-person-check-fill"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          12x.xx m<sup>3</sup>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Completed</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-check-circle-fill"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          2x.xx m<sup>3</sup>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Billed</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-receipt"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          2x.xx m<sup>3</sup>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div>
                      <h5 className="card-title">Activity</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <ul
                        className="activity-bar"
                        style={{
                          width: "100%",
                          listStyle: "none",
                          paddingLeft: "0",
                        }}
                      >
                        <li>
                          Excavation
                          <ProgressBar
                            striped
                            variant="info"
                            now={60}
                            label="Billed 40%"
                          />
                          <ProgressBar
                            striped
                            variant="success"
                            now={100}
                            label="Alloted 100%"
                          />
                        </li>

                        <li>
                          Concreting
                          <ProgressBar
                            striped
                            variant="info"
                            now={50}
                            label="Billed 50%"
                          />
                          <ProgressBar
                            striped
                            variant="success"
                            now={80}
                            label="Alloted 80%"
                          />
                        </li>

                        <li>
                          Pile Cap
                          <ProgressBar
                            striped
                            variant="info"
                            now={45}
                            label="Billed 45%"
                          />
                          <ProgressBar
                            striped
                            variant="success"
                            now={60}
                            label="Alloted 60%"
                          />
                        </li>

                        <li>
                          Panel
                          <ProgressBar
                            striped
                            variant="info"
                            now={40}
                            label="Billed 40%"
                          />
                          <ProgressBar
                            striped
                            variant="success"
                            now={50}
                            label="Alloted 50%"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Progress Chart</h5>
                    </div>
                    <Bar options={options} data={data} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Total Activity Wise
                      </h5>
                    </div>
                    <div
                      style={{
                        height: "400px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      <Doughnut data={pieData} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <h5>Top Performer Contractor</h5>
                <table className="table datatable datatable-table table-hover">
                  <thead>
                    <tr>
                      <th
                        data-sortable="true"
                        style={{ width: 7.383966244725738 }}
                      >
                        S. No
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 7.383966244725738 }}
                      >
                        Contractor Name
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 26.371308016877638 }}
                      >
                        Activity
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 26.371308016877638 }}
                      >
                        QTY Assigned
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 26.371308016877638 }}
                      >
                        QTY Executed
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 35.54852320675106 }}
                      >
                        QTY Billed
                      </th>
                      <th
                        data-sortable="true"
                        style={{ width: 10.759493670886076 }}
                      >
                        Last Billed Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                      <td>1</td>
                      <td>{DUMMY_USER[0].name}</td>
                      <td>Excavation</td>
                      <td>100</td>
                      <td>80</td>
                      <td>10</td>
                      <td>2024-05-13</td>
                    </tr>
                    <tr key="2">
                      <td>2</td>
                      <td>{DUMMY_USER[1].name}</td>
                      <td>Concreting</td>
                      <td>100</td>
                      <td>80</td>
                      <td>10</td>
                      <td>2024-05-13</td>
                    </tr>
                    <tr key="3">
                      <td>3</td>
                      <td>{DUMMY_USER[2].name}</td>
                      <td>Mounting</td>
                      <td>100</td>
                      <td>80</td>
                      <td>10</td>
                      <td>2024-05-13</td>
                    </tr>
                    <tr key="4">
                      <td>4</td>
                      <td>{DUMMY_USER[3].name}</td>
                      <td>Excavation</td>
                      <td>100</td>
                      <td>80</td>
                      <td>10</td>
                      <td>2024-05-13</td>
                    </tr>
                    <tr key="5">
                      <td>5</td>
                      <td>Ajay</td>
                      <td>Concreting</td>
                      <td>100</td>
                      <td>80</td>
                      <td>10</td>
                      <td>2024-05-13</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};
