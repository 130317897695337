import { useEffect, useState } from "react";
import { Http } from "../../utility/http";
import { apiHeaders } from "../../utility/generic";
import { Skelton } from "../shared/skelton";
import { DUMMY_USER } from "../../shared/user";
import { Link } from "react-router-dom";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

type Propos = {
  layer: string;
  status: string;
};

export const LayerList = ({ layer, status }: Propos) => {
  console.log("layer", status);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [listLayer, setlistLayer] = useState([
    {
      id: "",
      colmNumbe: "",
      name: "",
      actualQlt: "",
      actualDep: "",
      vendorNam: "",
      status: "",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const startIndex = (page - 1) * limit + 1;

  const handleLimitChange = (newLimit: any) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getLayerList = async () => {
    setLoading(true);
    await Http.request({
      url: `${process.env.REACT_APP_API_URL}layer/${layer}?page=${page}&limit=${limit}&search=${searchTerm}`,
      method: "get",
      headers: apiHeaders(),
    })
      .then((response) => {
        console.log("response", response);
        setlistLayer(response.list);
        setTotalItem(response.total);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setlistLayer([
          {
            id: "",
            colmNumbe: "",
            name: "",
            actualQlt: "",
            actualDep: "",
            vendorNam: "",
            status: "",
          },
        ]);
        if (error.response) {
          console.error("Server Error:", error.response.status);
        } else if (error.request) {
          console.error("Network Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });
  };
  useEffect(() => {
    getLayerList();
  }, [page, limit, searchTerm]);

  const searchByHandler = async (event: any) => {
    setSearchTerm(event.target.value);
  };

  const updateStatus = (item: any) => {
    /* console.log("Item", item);
    item.status = status;
    const index = listLayer.findIndex((list) => list.id == item.id);
    listLayer[index].status = status;
    setlistLayer(listLayer); */
  };
  return (
    <>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{capitalizeFirstLetter(status)}</h5>

          <div className="datatable-wrapper datatable-loading card-footer sortable searchable">
            <div className="datatable-top">
              <div className="datatable-dropdown">
                <label>
                  <select
                    className="pagination-select"
                    onChange={(e) => handleLimitChange(Number(e.target.value))}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </select>{" "}
                  Entries per page
                </label>
              </div>
              <div className="datatable-dropdown">
                <ButtonGroup>
                  <DropdownButton
                    as={ButtonGroup}
                    title="Export"
                    id="bg-nested-dropdown"
                  >
                    <Dropdown.Item eventKey="1">XLSX</Dropdown.Item>
                    <Dropdown.Item eventKey="2">PDF</Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
              </div>
              <div className="datatable-dropdown">
                <select className="form-control">
                  <option>--Filter--</option>
                  <option>Assigned</option>
                  <option>UnAssigned</option>
                </select>
              </div>

              <div className="datatable-search">
                <input
                  className="datatable-input search-box"
                  placeholder="Search..."
                  type="search"
                  title="Search within table"
                  onChange={searchByHandler}
                />
              </div>
            </div>
            <div className="datatable-container">
              <table className="table datatable datatable-table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 7.383966244725738 }}></th>
                    <th
                      data-sortable="true"
                      style={{ width: 7.383966244725738 }}
                    >
                      No
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 26.371308016877638 }}
                    >
                      Name
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 26.371308016877638 }}
                    >
                      Contractor Name
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 35.54852320675106 }}
                    >
                      Volume
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 10.759493670886076 }}
                    >
                      Depth
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 10.759493670886076 }}
                    >
                      Diameter
                    </th>
                    <th
                      data-sortable="true"
                      style={{ width: 10.759493670886076 }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <Skelton></Skelton>}
                  {!loading && listLayer.length === 0 && (
                    <tr>
                      <td colSpan={6}>No Data found.</td>
                    </tr>
                  )}
                  {!loading &&
                    listLayer.length > 0 &&
                    listLayer.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={item?.status ? true : false}
                              onChange={() => updateStatus(item)}
                            />

                            {/*  {item?.status && (
                              <span className="badge badge-success">
                                Assigned
                              </span>
                            )}

                            {!item?.status && (
                              <span className="badge badge-warning">
                                Un Assigned
                              </span>
                            )} */}
                          </td>
                          <td>{startIndex + index}</td>
                          <td>{item.name}</td>
                          <td>
                            <select>
                              <option value="">--Select--</option>
                              {DUMMY_USER.map((user) => {
                                return (
                                  <option
                                    selected={user.name === item.vendorNam}
                                    value={user.name}
                                  >
                                    {user.name}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                          <td>{item.volume}</td>
                          <td>{item.depth}</td>
                          <td>{item.diameter}</td>
                          <td>{item.status}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="datatable-bottom">
              <div className="datatable-info">
                Showing {(page - 1) * limit + 1} to{" "}
                {Math.min(page * limit, totalItem)} entries of {totalItem}
              </div>
              <nav className="datatable-pagination">
                <button
                  className={`btn pagination-btn`}
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  className={`btn pagination-btn`}
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page * limit >= totalItem}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </nav>
            </div>
            <button className="btn btn-primary">Approve</button>
          </div>
        </div>
      </div>
    </>
  );
};
