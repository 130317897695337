export const DUMMY_USER = [
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd1",
    name: "Ajay",
    email: "ajay@yopmail.com",
    verificationStatus: "approved",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd2",
    name: "Aniket",
    email: "Aniket@yopmail.com",
    verificationStatus: "approved",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd3",
    name: "Rahul",
    email: "Rahul@yopmail.com",
    verificationStatus: "reject",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd4",
    name: "Vikas",
    email: "Vikas@yopmail.com",
    verificationStatus: "approved",
  },
];

export const ENGINNER_USER = [
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd1",
    name: "Sandeep",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd2",
    name: "Jogesh",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd3",
    name: "Vinit",
  },
  {
    id: "e81be723-d1b0-41df-812f-7bf3e9227cd4",
    name: "Vijay",
  },
];
