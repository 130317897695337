// @ts-nocheck
import { MainLayout } from "../layout/main-layout";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { loadModules } from "esri-loader";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import * as Yup from "yup";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DUMMY_USER, ENGINNER_USER } from "../shared/user";
import { Http } from "../utility/http";
import { LayerList } from "../components/dashboard/layer-list";
import p1 from "../assets/images/excavator.png";
import p2 from "../assets/images/concreting.png";
import p3 from "../assets/images/mounting.png";
import p4 from "../assets/images/solar.png";
import MapView from "@arcgis/core/views/MapView.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Map from "@arcgis/core/Map.js";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import CSVLayer from "@arcgis/core/layers/CSVLayer.js";
import Basemap from "@arcgis/core/Basemap.js";
import FeatureTable from "@arcgis/core/widgets/FeatureTable.js";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";

export const Home = () => {
  const mapDivRef = useRef(null);
  const [selectedLayer, setSelectedLayer] = useState("excavated");
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rejectedEntries, setRejectedEntries] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [featureDetails,setFeatureDetails]=useState({})
  const handleClose = () => {
    setShow(false);
    reset();
    setRejectedEntries([]);
  };

  const handleCloseList = () => {
    setShowList(false);
  };

  const handleShow = () => setShow(true);

  const validationSchema = Yup.object().shape({
    user: Yup.string().required("Contractor is required."),
    /* range: Yup.array().of(
      Yup.object().shape({
        from1: Yup.string().required("From1 is required"),
        to1: Yup.string().required("To1 is required"),
        from2: Yup.string().required("From2 is required"),
        to2: Yup.string().required("To2 is required"),
      })
    ), */
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  /* const { fields, append, remove } = useFieldArray({
    control,
    name: "range",
  }); */

  const onSubmitHandler = (data: any) => {
    console.log(data);
    setLoading(true);
    const requestParams = {
      layer: selectedLayer,
      userName: data.user,
      status: status,
      /* range: data.range.map((element: any) => {
        return {
          from1: parseInt(element.from1),
          to1: parseInt(element.to1),
          from2: element.from2,
          to2: element.to2,
        };
      }), */
      range: selectedGeometry,
    };

    Http.request({
      method: "put",
      url: `${process.env.REACT_APP_API_URL}layer`,
      data: requestParams,
    })
      .then((response) => {
        reset();
        setLoading(false);
        //loadMap();
        if (response.rejectedEntries.length == 0) {
          handleClose();
        } else {
          setRejectedEntries(response.rejectedEntries);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [lname, selecteLname] = useState(["excavation"]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [mapView, setMapView] = useState(null);
  const [layers, setLayers] = useState(null);
  const [selectedGeometry, setSelectedGeometry] = useState([]);

  useEffect(() => {
    if (mapView) {
      console.log("I am in ");
      return;
    }

    const map = new Map({
      basemap: "streets-vector",
    });

    const view = new MapView({
      container: mapDivRef.current,
      //container: "viewDiv",
      map: map,
      zoom: 13,
      center: [-122.43, 37.78], // Your center coordinates
    });

    const featureLayerUrl = `https://arcgis.lgeom.com/arcgisserver/rest/services/Map2/MapServer/0`;
    const featureLayer = new FeatureLayer({ url: featureLayerUrl });
    featureLayer
      .when(() => {
        return featureLayer.queryExtent();
      })
      .then((res) => {
        if (res.extent) {
          view.goTo(res.extent).catch(function (error) {
            console.error(error, "-----");
          });
        }
      });
    let featureLayerView;
    featureLayer
      .when(() => {
        view.whenLayerView(featureLayer).then(function (layerView) {
          featureLayerView = layerView;
        });
      })
      .catch(errorCallback);

    // create a new instance of a FeatureTable
    const featureTable = new FeatureTable({
      view: view,
      layer: featureLayer,
      highlightOnRowSelectEnabled: false,
      fieldConfigs: [
        {
          name: "unit_name",
          label: "Name",
        },
        {
          name: "state",
          label: "State",
        },
        {
          name: "region",
          label: "Region",
        },
        {
          name: "unit_type",
          label: "Type",
        },
        {
          name: "created_by",
          label: "Created by",
        },
        {
          name: "date_est",
          label: "Established date",
        },
        {
          name: "description",
          label: "Description",
        },
        {
          name: "caption",
          label: "Caption",
        },
      ],
      container: document.getElementById("tableDiv"),
    });

    // this array will keep track of selected feature objectIds to
    // sync the layerview feature effects and feature table selection
    let features = [];

    // Check if the highlights are being changed on the table
    // update the features array to match the table highlights
    featureTable.highlightIds.on("change", async (event) => {
      // set excluded effect on the features that are not selected in the table
      event.removed.forEach((item) => {
        const data = features.find((data) => {
          return data === item;
        });
        if (data) {
          features.splice(features.indexOf(data), 1);
        }
      });

      // If the selection is added, push all added selections to array
      event.added.forEach((item) => {
        features.push(item);
      });

      featureLayerView.featureEffect = {
        filter: {
          objectIds: features,
        },
        excludedEffect: "blur(5px) grayscale(90%) opacity(40%)",
      };
    });

    const polygonGraphicsLayer = new GraphicsLayer();
    map.add(polygonGraphicsLayer);

    // add the select by rectangle button the view
    view.ui.add("select-by-rectangle", "top-left");
    const selectButton = document.getElementById("select-by-rectangle");

    // click event for the select by rectangle button
    selectButton.addEventListener("click", () => {
      view.closePopup();
      sketchViewModel.create("rectangle");
    });

    // add the clear selection button the view
    view.ui.add("clear-selection", "top-left");
    document.getElementById("clear-selection").addEventListener("click", () => {
      featureTable.highlightIds.removeAll();
      featureTable.filterGeometry = null;
      polygonGraphicsLayer.removeAll();
    });

    // create a new sketch view model set its layer
    const sketchViewModel = new SketchViewModel({
      view: view,
      layer: polygonGraphicsLayer,
    });

    // Once user is done drawing a rectangle on the map
    // use the rectangle to select features on the map and table
    sketchViewModel.on("create", async (event) => {
      if (event.state === "complete") {
        // this polygon will be used to query features that intersect it
        const geometries = polygonGraphicsLayer.graphics.map(function (
          graphic
        ) {
          return graphic.geometry;
        });
        const queryGeometry = await geometryEngineAsync.union(
          geometries.toArray()
        );
        selectFeatures(queryGeometry);
      }
    });

    // This function is called when user completes drawing a rectangle
    // on the map. Use the rectangle to select features in the layer and table

    map.addMany([featureLayer]);

    view.on("click", (event) => {
      // Use hitTest to identify features at the click location
      view
        .hitTest(event.screenPoint)
        .then((response) => {
          const hitResults = response.results;

          // Check if a feature was clicked (hitResults might be empty)
          if (hitResults.length > 0) {
            const feature = hitResults[0].graphic.attributes;

            // Access and display feature details
            console.log("Feature details:");
            setIsOpen(true)
            setFeatureDetails(feature)
            for (const attribute in feature) {
              console.log(`${attribute}: ${feature[attribute]}`);
            }

            // You can customize how to display details (e.g., popup, modal)
            // ...
          } else {
            console.log("No feature clicked");
          }
        })
        .catch((error) => console.error("Error during hitTest:", error));
    });

    setMapView(view);

    function selectFeatures(geometry) {
      if (featureLayerView) {
        const query = {
          geometry: geometry,
          outFields: ["*"],
        };

        // query graphics from the csv layer view. Geometry set for the query
        // can be polygon for point features and only intersecting geometries are returned
        featureLayerView
          .queryFeatures(query)
          .then((results) => {
            if (results.features.length === 0) {
              clearSelection();
            } else {
              console.log("results.features", results.features);
              let selectedGeometry = results.features.map((res) => {
                return res.attributes.OBJECTID;
              });
              setSelectedGeometry(selectedGeometry);
              featureTable.filterGeometry = geometry;
              featureTable.selectRows(results.features);
            }
          })
          .catch(errorCallback);
      }
    }

    function errorCallback(error) {
      console.log("error happened:", error.message);
    }
  }, []);

  useEffect(() => {
    if (mapView) {
      if (layers) {
        // If the layer exists, remove it
        mapView.map.remove(layers);
        setLayers(null);
      } else {
        let layerNo = 0;
        if (selectedLayer === "concrete") {
          layerNo = 1;
        } /* else if (selectedLayer === "excavated") {
    } */
        // If the layer does not exist, add it
        const newFeatureLayer = new FeatureLayer({
          url: `https://arcgis.lgeom.com/arcgisserver/rest/services/Map2/MapServer/${layerNo}`,
        });
        mapView.map.add(newFeatureLayer);
        setLayers(newFeatureLayer);
      }
    }
  }, [selectedLayer]);

  


  return (
    <>
      <MainLayout>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {capitalizeFirstLetter(status)}:
              {capitalizeFirstLetter(selectedLayer)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row-reverse",
                cursor: "pointer",
              }}
              onClick={() => setShowList(true)}
            >
              See List
            </label>
            <form
              onSubmit={handleSubmit(onSubmitHandler)}
              className="assign-form"
            >
              <div className="row mb-3 ">
                <label htmlFor="inputFirstName3">Contractor :</label>
                <select
                  className={`form-control ${errors.user ? "is-invalid" : ""}`}
                  {...register("user")}
                >
                  <option value="">--Select--</option>
                  {DUMMY_USER.map((user) => {
                    return <option value={user.name}>{user.name}</option>;
                  })}
                </select>
                <div className="invalid-feedback">{errors.user?.message}</div>
              </div>

              <div className="row mb-3 ">
                <label htmlFor="inputFirstName3">Engineer Incharge :</label>
                <select className={`form-control`}>
                  <option value="">--Select--</option>
                  {ENGINNER_USER.map((user) => {
                    return <option value={user.name}>{user.name}</option>;
                  })}
                </select>
              </div>

              {/* <div className="row mb-3">
                {fields.map((field, index) => (
                  <div key={field.id} className="range_group">
                    <div>
                      <label>Row</label>
                      <div className="from-to-range">
                        <div className="range-input">
                          <input
                            className="form-control"
                            {...register(`range.${index}.from1`)}
                            placeholder="From"
                          />
                          {errors?.range?.[index]?.from1 && (
                            <p className="error-message">
                              {errors?.range?.[index]?.from1?.message}
                            </p>
                          )}
                        </div>

                        <div className="range-input">
                          <input
                            className="form-control"
                            {...register(`range.${index}.to1`)}
                            placeholder="To"
                          />
                          {errors?.range?.[index]?.to1 && (
                            <p className="error-message">
                              {errors.range[index]?.to1?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label>Column</label>
                      <div className="from-to-range">
                        <div className="range-input">
                          <input
                            className="form-control"
                            {...register(`range.${index}.from2`)}
                            placeholder="From"
                          />
                          {errors?.range?.[index]?.from2 && (
                            <p className="error-message">
                              {errors?.range?.[index]?.from2?.message}
                            </p>
                          )}
                        </div>

                        <div className="range-input">
                          <input
                            className="form-control"
                            {...register(`range.${index}.to2`)}
                            placeholder="To"
                          />
                          {errors?.range?.[index]?.to2 && (
                            <p className="error-message">
                              {errors.range[index]?.to2?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {index > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => remove(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    append({ from1: "", to1: "", from2: "", to2: "" })
                  }
                >
                  Add Range
                </button>
                {rejectedEntries.length > 0 && (
                  <>
                    <h5 className="mt-3">Rejected Entries</h5>
                    <table className="table">
                      <thead>
                        <th>Row</th>
                        <th>Column</th>
                      </thead>
                      <tbody>
                        {rejectedEntries.map((entry: any) => {
                          return (
                            <tr>
                              <td>{entry.i}</td>
                              <td>{entry.j}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </div> */}

              {status === "assign" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">Work Order File :</label>
                  <input type="file" className="form-control" />
                </div>
              )}

              {status === "bill" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">Estimated QTY :</label>
                  <input
                    type="text"
                    value={200}
                    readOnly
                    className="form-control"
                  />
                </div>
              )}

              {status === "bill" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">
                    Site Inspection Report :
                  </label>
                  <input type="file" className="form-control" />
                </div>
              )}

              {status === "assign" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">Auto CAD File :</label>
                  <input type="file" className="form-control" />
                </div>
              )}

              {status === "bill" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">Upload Bill :</label>
                  <input type="file" className="form-control" />
                </div>
              )}

              {status === "bill" && (
                <div className="row mb-3 ">
                  <label htmlFor="inputFirstName3">Site Image :</label>
                  <input type="file" className="form-control" />
                </div>
              )}

              {status === "assign" && (
                <div className="row mb-3 ">
                  <div className="col-lg-6">
                    <label htmlFor="inputFirstName3">Start Date :</label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="inputFirstName3">End Date :</label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
              )}

              {status === "bill" && (
                <div className="row mb-3 ">
                  <div className="col-lg-6">
                    <label htmlFor="inputFirstName3">
                      Site Completion Date :
                    </label>
                    <input type="date" className="form-control" />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="inputFirstName3">
                      Site Inspected Date :
                    </label>
                    <input type="date" className="form-control" />
                  </div>
                </div>
              )}

              <div className="text-center">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary mx-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={showList} onHide={handleCloseList}>
          <Modal.Header closeButton>
            <Modal.Title>{capitalizeFirstLetter(selectedLayer)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LayerList layer={selectedLayer} status={status}></LayerList>
          </Modal.Body>
        </Modal>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-2">
              <div className="form-group mb-3">
                <label>Select Site</label>
                <select className="form-control">
                  <option value="">--Select--</option>
                  <option value="Khawda">Khawda</option>
                  <option value="Devi kot">Devi kot</option>
                  <option value="Surendra Nagar">Surendra Nagar</option>
                  <option value="Bhav Nagar">Bhav Nagar</option>
                </select>
              </div>

              <div className="form-group mb-5">
                <label>Select Block</label>
                <select className="form-control">
                  <option value="">--Select--</option>
                  <option value="1">Block 1</option>
                  <option value="2">Block 2</option>
                  <option value="3">Block 3</option>
                  <option value="4">Block 4</option>
                  <option value="5">Block 5</option>
                </select>
              </div>

              <span style={{ fontWeight: "500" }}>Activities</span>
              <ul className="list-group">
                <li
                  className={`list-group-item a-link ${
                    selectedLayer === "excavated" ? "selected-item" : ""
                  }`}
                  onClick={() => {
                    setSelectedLayer("excavated");
                  }}
                >
                  <img src={p1} alt="x" /> &nbsp;&nbsp; Excavated
                </li>
                <li
                  className={`list-group-item a-link ${
                    selectedLayer === "concrete" ? "selected-item" : ""
                  }`}
                  onClick={() => {
                    setSelectedLayer("concrete");
                  }}
                >
                  <img src={p2} alt="x" /> &nbsp;&nbsp; Concrete
                </li>
                <li className="list-group-item disabled-link">
                  {" "}
                  <img src={p3} alt="x" /> &nbsp;&nbsp; Mounting
                </li>
                <li className="list-group-item disabled-link">
                  <img src={p4} alt="x" />
                  &nbsp;&nbsp; Panel
                </li>
              </ul>

              <ButtonGroup className="mt-5">
                <DropdownButton
                  as={ButtonGroup}
                  title="Action"
                  id="bg-nested-dropdown"
                >
                  <Dropdown.Item
                    eventKey="1"
                    onClick={() => {
                      handleShow();
                      setStatus("assign");
                    }}
                  >
                    Assign
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    onClick={() => {
                      handleShow();
                      setStatus("bill");
                    }}
                  >
                    Validation
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">Arrpove</Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
            <div className="col-lg-10">
              <div
                key="map-container"
                ref={mapDivRef}
                id="viewDiv"
                style={{ height: "80vh", width: "100%" }}
              />
              <div
                id="select-by-rectangle"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Select features by rectangle"
                style={{
                  position: "fixed",
                  top: "100px",
                  left: "370px",
                }}
              >
                <span
                  className="esri-icon-checkbox-unchecked"
                  style={{ cursor: "pointer", fontSize: "25px" }}
                >
                  {" "}
                  <i className="bi bi-check2-square"></i>
                </span>
              </div>
              <div
                id="clear-selection"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Clear selection"
                style={{
                  position: "fixed",
                  top: "130px",
                  left: "370px",
                }}
              >
                <span
                  className="esri-icon-erase"
                  style={{ cursor: "pointer", fontSize: "25px" }}
                >
                  <i className="bi bi-eraser"></i>
                </span>
              </div>
              {/* <div key="table" className="container">
                <div id="tableDiv"></div>
              </div> */}
            </div>
          </div>
        </section>

        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Feature Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sample</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </MainLayout>
    </>
  );
};
